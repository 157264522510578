<script setup>
import {
  defineAsyncComponent,
  watch,
  markRaw,
  ref,
  nextTick,
  shallowReactive,
} from 'vue'
import { useMakeID } from '@/utils'
import Draggable from 'vuedraggable'

const { makeID } = useMakeID()

const props = defineProps({
  modelValue: Array,
  excludeFields: {
    type: Array,
    default: () => [],
  },
})
const emit = defineEmits(['update:modelValue'])
const formBuilder = ref(null)

const fieldComponents = markRaw({
  textfield: ['Text Input'],
  textarea: ['Textarea'],
  checkbox: [
    'Checkboxes',
    defineAsyncComponent({
      loader: () => import('@/components/admin/formBuilder/CheckboxField.vue'),
    }),
  ],
  select: [
    'Dropdown',
    defineAsyncComponent({
      loader: () => import('@/components/admin/formBuilder/SelectField.vue'),
    }),
  ],
  file: [
    'File Upload',
    defineAsyncComponent({
      loader: () => import('@/components/admin/formBuilder/FileField.vue'),
    }),
  ],
})

if (props.excludeFields.length > 0) {
  props.excludeFields.forEach((f) => delete fieldComponents[f])
}

const customFields = ref(
  props.modelValue
    ? props.modelValue.map((field) => {
        const rf = shallowReactive(field)

        watch(
          rf,
          (val) => {
            const component = fieldComponents[val.type][1]
            if (!component) val.component = undefined
            else val.component = markRaw(component)
          },
          { immediate: true }
        )

        return rf
      })
    : []
)

watch(
  customFields,
  (val) => {
    emit(
      'update:modelValue',
      val.map((cf) => ({ ...cf, component: undefined }))
    )
  },
  { deep: true }
)

function addNewField() {
  const newField = shallowReactive({
    _tempId: makeID(),
    type: 'textfield',
    label: '',
    hint: '',
    required: false,
  })

  watch(newField, (val) => {
    const component = fieldComponents[val.type][1]
    if (!component) val.component = undefined
    else val.component = markRaw(component)
  })

  customFields.value.push(newField)

  nextTick(() => {
    formBuilder.value.querySelector('ol>li:last-child .label-field').focus()
  })
}

if (customFields.value.length === 0) {
  // Add initial placeholder field
  addNewField()
}

function handleLabelInput(e, element) {
  const val = e.target.innerText

  element.label = val
}
</script>

<template>
  <div ref="formBuilder" class="field-sorter">
    <draggable
      v-model="customFields"
      :animation="200"
      handle=".handle"
      tag="ol"
      item-key="id"
    >
      <template #item="{ element, index }">
        <li :key="`formBuilder_${element.id}`">
          <div class="controls">
            <i class="fas fa-grip-dots-vertical handle"></i>
            <a href="#" @click.prevent="customFields.splice(index, 1)"
              ><i class="fas fa-trash"></i
            ></a>
          </div>

          <div class="field-control">
            <header>
              <span class="counter">{{ index + 1 }}.</span>
              <div
                class="label-field"
                contenteditable="true"
                @blur="handleLabelInput($event, element)"
                @keydown.enter.prevent
                v-text="element.label"
              ></div>
            </header>

            <div class="universal-attr">
              <div class="field-wrap">
                <label>Field type</label>
                <select
                  v-model="element.type"
                  class="form-select form-select-sm"
                >
                  <option
                    v-for="[fieldId, [fieldName]] in Object.entries(
                      fieldComponents
                    )"
                    :key="`cf_${element.id}_fieldSelector_${fieldId}`"
                    :value="fieldId"
                  >
                    {{ fieldName }}
                  </option>
                </select>
              </div>

              <div class="field-wrap">
                <label>Field required</label>
                <div class="form-check form-switch form-switch-lg">
                  <input
                    :id="`requiredToggle_${element.id}`"
                    v-model="element.required"
                    class="form-check-input"
                    type="checkbox"
                  />
                </div>
              </div>

              <div class="field-wrap flex-grow-1">
                <label>Hint text</label>
                <input
                  v-model="element.hint"
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="(optional) Enter a message to help a user complete this field..."
                />
              </div>
            </div>

            <component
              :is="element.component"
              v-if="element.component"
              v-model="customFields[index]"
            />
          </div>
        </li>
      </template>
    </draggable>

    <button class="btn btn-white btn-sm" @click.prevent="addNewField">
      <i class="fas fa-plus"></i> Add a field
    </button>
  </div>
</template>

<style lang="scss" scoped>
.field-sorter {
  > ol {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 15px;
    padding: 0;
    margin: 0 0 15px;

    > li {
      @include static-card;
      display: flex;

      > .controls {
        width: 45px;
        flex: 0 0 45px;
        margin: 0 0 0 -15px;

        .handle {
          display: block;
          width: 35px;
          margin: 0;
          height: 31px;
          text-align: center;
          line-height: 31px;
          color: $muted-text;
          cursor: ns-resize;
        }

        > a {
          display: block;
          font-size: 12px;
          height: 31px;
          line-height: 31px;
          text-align: left;
          text-indent: 6px;
          text-decoration: none;
          color: $danger;
          overflow: hidden;
          width: 0;
          margin: 0;
          transition: width 0.2s $curve 0.2s;
        }
      }

      &:hover {
        > .controls {
          > a {
            width: 35px;
          }
        }
      }

      > .field-control {
        flex: 1;

        > header {
          display: flex;
          align-items: center;
          column-gap: 25px;
          margin: 0 0 15px;

          > span {
            font-weight: 600;
            font-size: 16px;
            margin: 0;
            flex: 0 0 auto;
            font-family: $font-family-sans-serif;
          }

          > .label-field {
            background-image: url("data:image/svg+xml,%3Csvg width='100%25' height='2' viewBox='0 0 100%25 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='1' y1='1' x2='100%25' y2='1' stroke='%23DEDCD8' stroke-width='2' stroke-linecap='round' stroke-dasharray='4 7'/%3E%3C/svg%3E%0A");
            background-size: 100% 2px;
            background-position: 0 bottom;
            background-repeat: no-repeat;
            outline: none;
            padding: 5px 2px;
            flex: 1;
            display: block;

            &:empty::before {
              content: 'Enter a title for this field...';
              pointer-events: none;
              color: $muted-text;
            }
          }
        }

        > .universal-attr {
          display: flex;
          column-gap: 25px;
        }

        > .field-settings {
          margin: 15px 0 0;
        }
      }
    }

    > button {
      margin: 15px auto;
    }
  }
}

.sortable-ghost {
  // opacity: 0;
}

.slide-list-move {
  transition: transform 0.2s $curve;
}
</style>
