<script setup>
import { computed } from 'vue'
import ProfilePicture from './ProfilePicture.vue'

const props = defineProps({
  user: Object,
  href: Object,
  size: { default: 32, type: Number },
})

const fullName = computed(() =>
  props.user?.first_name
    ? props.user?.full_name ||
      `${props.user.first_name} ${props.user.last_name}`
    : props.user.email?.split('@')[0]
)

const useNameLink = props.href !== undefined
</script>

<template>
  <div class="user--card">
    <ProfilePicture :user="props.user" :size="props.size" />
    <div class="name">
      <strong>
        <router-link v-if="useNameLink" :to="props.href">{{
          fullName
        }}</router-link>
        <template v-else>
          {{ fullName }}
        </template>
      </strong>
      <slot></slot>
    </div>
  </div>
</template>
